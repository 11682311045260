<template>
  <action-dispatcher action="content/getModules">
    <div class="view-admin-exams">
      <router-link
        v-for="(m, i) in modules"
        :key="`module-exam-${i}`"
        class="card p-3 mb-4"
        :to="{ name: 'admin-module-questions', params: { id: m.id } }"
      >
        Prova: {{ m.name }}
      </router-link>
    </div>
  </action-dispatcher>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("content", ["modules"])
  }
};
</script>
<style lang="scss" scoped>
.view-admin-exams {
}
</style>
